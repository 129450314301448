import styled, { css } from 'styled-components';
import { FeatureCertificationProps } from './FeatureCertification';
import { scrollingHorizontalVisible } from '@/styles/utilities';
import { imageBlurredFadeIn } from '@/components/image/imageLoadedFadeIn.styles';
import { imagePulse } from '@/components/image/imagePulse.styles';

export const Element = styled.div<FeatureCertificationProps>`
  ${({ theme, ...props }) => {
    return css`
      //
    `;
  }};
`;

export const CertificationTravelMade = styled.div`
  ${({ theme, ...props }) => {
    return css`
      margin: 0 auto ${theme.spacing.base} auto;
      max-width: 300px;
      position: relative;
      height: 10rem;
      > img {
        align-items: center;
        object-fit: contain;
        // Blur effect
        ${imageBlurredFadeIn};
        &.img-loaded {
          opacity: 1 !important;
        }
      }
      // Pulse effect
      ${imagePulse};
    `;
  }};
`;

export const CertificationBadges = styled.div`
  ${({ theme, ...props }) => {
    return css`
      display: flex;
      align-items: center;
      align-content: center;
      gap: ${theme.spacing.base};

      overflow-x: scroll;
      ${scrollingHorizontalVisible};

      @media screen and (min-width: ${theme.breakpoint.sm}) {
        justify-content: center;
        gap: ${theme.spacing.md};
      }
    `;
  }};
`;
