import React from 'react';
import FeatureCertification from '@/features/home/components/FeatureCertification/FeatureCertification';
import SectionFeatured from '@/components/section/SectionFeatured/SectionFeatured';
import SectionFeaturedContent from '@/components/section/SectionFeaturedContent/SectionFeaturedContent';

const SectionCertification = () => {
  return (
    <SectionFeatured title="Certificações" bgColor="white">
      <SectionFeaturedContent>
        <FeatureCertification />
      </SectionFeaturedContent>
    </SectionFeatured>
  );
};

export default SectionCertification;
