import React from 'react';
import { Element } from './CertificationBadgeImage.styles';
import Image from '@/providers/Image';
import useImagePulse from '@/components/image/imagePulseHook';

type CertificationBadgeImageProps = {
  badge: {
    title: string;
    image: string;
  };
};

const CertificationBadgeImage = ({ badge, ...props }: CertificationBadgeImageProps) => {
  const { className, containerRef, handleOnCompleted } = useImagePulse();
  return (
    <Element className={className} ref={containerRef}>
      <Image
        fill
        src={badge.image}
        alt={badge.title}
        placeholder={`data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wEEEABQAFAAUABQAFUAUABaAGQAZABaAH0AhwB4AIcAfQC5AKoAmwCbAKoAuQEYAMgA1wDIANcAyAEYAakBCQE2AQkBCQE2AQkBqQF3AccBcgFZAXIBxwF3AqMCEgHWAdYCEgKjAwwCjwJsAo8DDAOxA00DTQOxBKYEagSmBhMGEwgqEQBQAFAAUABQAFUAUABaAGQAZABaAH0AhwB4AIcAfQC5AKoAmwCbAKoAuQEYAMgA1wDIANcAyAEYAakBCQE2AQkBCQE2AQkBqQF3AccBcgFZAXIBxwF3AqMCEgHWAdYCEgKjAwwCjwJsAo8DDAOxA00DTQOxBKYEagSmBhMGEwgq/8IAEQgABgAKAwEiAAIRAQMRAf/EABUAAQEAAAAAAAAAAAAAAAAAAAAE/9oACAEBAAAAALX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oACAECEAAAAH//xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oACAEDEAAAAH//xAAYEAEAAwEAAAAAAAAAAAAAAAACARAREv/aAAgBAQABPwBFdRir/8QAFBEBAAAAAAAAAAAAAAAAAAAAAP/aAAgBAgEBPwB//8QAFBEBAAAAAAAAAAAAAAAAAAAAAP/aAAgBAwEBPwB//9k=`}
        fadeInOnBlur
        handleOnCompleted={handleOnCompleted}
      />
    </Element>
  );
};

export default CertificationBadgeImage;
