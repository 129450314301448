import React, { MouseEvent, ReactNode } from 'react';
import { CertificationBadges, CertificationTravelMade, Element } from './FeatureCertification.styles';
import Image from '@/providers/Image';
import data from './badges-data.json';
import CertificationBadgeImage from '@/features/home/components/FeatureCertification/CertificationBadgeImage';
import useImagePulse from '@/components/image/imagePulseHook';

export interface FeatureCertificationProps {
  className?: string;
  children?: ReactNode;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void | undefined;
}

const FeatureCertification = ({ children, ...props }: FeatureCertificationProps) => {
  const { travelMade, badges } = data;
  const { className, containerRef, handleOnCompleted } = useImagePulse();
  return (
    <Element>
      <CertificationTravelMade className={className} ref={containerRef}>
        <Image
          src={travelMade.image}
          alt={travelMade.title}
          fill
          placeholder={`data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wEEEABQAFAAUABQAFUAUABaAGQAZABaAH0AhwB4AIcAfQC5AKoAmwCbAKoAuQEYAMgA1wDIANcAyAEYAakBCQE2AQkBCQE2AQkBqQF3AccBcgFZAXIBxwF3AqMCEgHWAdYCEgKjAwwCjwJsAo8DDAOxA00DTQOxBKYEagSmBhMGEwgqEQBQAFAAUABQAFUAUABaAGQAZABaAH0AhwB4AIcAfQC5AKoAmwCbAKoAuQEYAMgA1wDIANcAyAEYAakBCQE2AQkBCQE2AQkBqQF3AccBcgFZAXIBxwF3AqMCEgHWAdYCEgKjAwwCjwJsAo8DDAOxA00DTQOxBKYEagSmBhMGEwgq/8IAEQgABgAKAwEiAAIRAQMRAf/EABUAAQEAAAAAAAAAAAAAAAAAAAAE/9oACAEBAAAAALX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oACAECEAAAAH//xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oACAEDEAAAAH//xAAYEAEAAwEAAAAAAAAAAAAAAAACARAREv/aAAgBAQABPwBFdRir/8QAFBEBAAAAAAAAAAAAAAAAAAAAAP/aAAgBAgEBPwB//8QAFBEBAAAAAAAAAAAAAAAAAAAAAP/aAAgBAwEBPwB//9k=`}
          fadeInOnBlur
          handleOnCompleted={handleOnCompleted}
        />
      </CertificationTravelMade>
      <CertificationBadges>
        {badges.map((badge, index) => (
          <CertificationBadgeImage key={`badge-${index}`} badge={badge} />
        ))}
      </CertificationBadges>
    </Element>
  );
};

export default FeatureCertification;
