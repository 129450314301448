import styled, { css } from 'styled-components';
import { imageBlurredFadeIn } from '@/components/image/imageLoadedFadeIn.styles';
import { imagePulse } from '@/components/image/imagePulse.styles';

export const Element = styled.div`
  ${({ theme, ...props }) => {
    return css`
      position: relative;
      min-width: 45px;
      min-height: 45px;
      @media screen and (min-width: ${theme.breakpoint.sm}) {
        min-width: 60px;
        min-height: 60px;
      }

      // Pulse effect: NOTE: Must come first to img, otherwise the pulse effect will not work
      ${imagePulse};
      
      img {
        object-fit: contain;
        // Blur effect
        ${imageBlurredFadeIn};
        // Override the blur effect
        scale: 1 !important;
        &.img-loaded {
          opacity: 1 !important; 
          scale: 1 !important;
      }
    `;
  }};
`;
